<template>
  <!-- <div class="about">
    <va-alert description="aqui vai um alerta"></va-alert>
    <h1>This is an about page</h1>
    <h3>{{ abc }}</h3>
  </div> -->
  <!-- <div class="about">
    <h1>{{ header }}</h1>
  </div> -->
  <div class="buttons">
    <div class="row">
      <div class="flex xs12">
        <va-card class="larger-padding">
          <va-card-title>BUTTON TYPES</va-card-title>
          <va-card-content class="row">
            <div class="flex">
              <va-button
                class="mr-2 mb-2"
                @click="getOrder"
              >
                Default
              </va-button>
              <va-button
                class="mr-2 mb-2"
                disabled
              >
                Disabled
              </va-button>
              <va-button
                class="mr-2 mb-2"
                outline
              >
                Outline
              </va-button>
              <va-button
                class="mr-2 mb-2"
                outline
                disabled
              >
                Disabled
              </va-button>
              <va-button
                class="mr-2 mb-2"
                flat
              >
                Flat
              </va-button>
              <va-button
                class="mr-2 mb-2"
                flat
                disabled
              >
                Disabled
              </va-button>
            </div>
          </va-card-content>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>
